import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Swiper from 'swiper';
import { Link } from 'gatsby';
import BannerComponent from './page/bannerComponent';

let PreviousIssues = ({ title, content }) => {
  let data = useStaticQuery(graphql`
    query previousMagazineIssues {
      allMysqlMagazines {
        nodes {
          banner_portrait
          mysqlId
          mysqlImages {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                src
                srcSet
                aspectRatio
                sizes
              }
            }
          }
        }
      }
    }
  `);

  let magazines = data.allMysqlMagazines.nodes;

  useEffect(() => {
    new Swiper('.magazines-slider', {
      speed: 400,
      spaceBetween: 17,
      slidesPerView: 2,
      breakpoints: {
        320: {
          slidesPerView: 2,
        },
        480: {
          slidesPerView: 3,
        },
        640: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
    });
  });

  return (
    <section className='container anthem'>
      <div className='py-5 text-center'>
        <h1 className='intro-title'>{title}</h1>

        <p
          className='my-4'
          dangerouslySetInnerHTML={{
            __html: content,
          }}></p>

        <div>
          <div class='swiper-container magazines-slider'>
            <div class='swiper-wrapper'>
              {magazines.map((d, i) => (
                <div class='swiper-slide' key={i}>
                  <Link
                    to={'/magazine/' + d.mysqlId}
                    className='magazine-cover'>
                    <BannerComponent
                      value={d.mysqlImages[1]}
                      type={1}></BannerComponent>
                  </Link>
                </div>
              ))}
            </div>
            <div class='swiper-pagination'></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PreviousIssues;
